<template>
  <div class="personalFi-personal-info">
    <Header v-bind:type="type" />
    <Breadcrumb_personalFi />
    <MainId />
    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title"><span class="bold-title">Personal Financial Statement</span> as of <span id="current_date"></span></div>

          <h1 class="section-title">Personal Information</h1>
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img class="page-error-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="field-group">
            <div class="ll-row row1-container">
              <!-- First Name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" id="business-label-app-fname"><label for="pfs-pers-fname">First Name<sup class="fhb-red">*</sup></label></div>
                <input maxlength="512" class="ll-textfield tf-fname" data-test="business-fname" type="text" id="pfs-pers-fname" name="first-name" aria-describedby="pfs-pers-fname-error"
                  v-model="appFName" :class="missingFName || invalidFName ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateFName" required>
                <div id="pfs-pers-fname-error" class="ind-err-container">
                  <div v-if="missingFName || invalidFName" class="form-error-msg" data-test="business-fname-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
              </div>
              <!-- Middle Initial -->
              <div class="ll-item ll-middle-initial">
                <div class="tf-label" id="business-label-app-mi"><label for="pfs-pers-mi">MI</label></div>
                <input maxlength="1" class="ll-textfield tf-middle-initial" data-test="business-mi" type="text" id="pfs-pers-mi" aria-describedby="pfs-pers-mi-error" name="middle-initial"
                  v-model="appMI" :class="appMI && invalidMName ? 'll-textfield-error' : 'll-textfield'"
                  @input="validateMI">
                <div id="pfs-pers-mi-error" class="ind-err-container">
                  <div v-if="appMI && invalidMName" class="form-error-msg" data-test="business-mi-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid middle initial</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <!-- Last Name -->
            <div class="ll-item ll-lname">
              <div class="tf-label" id="business-label-app-lname"><label for="pfs-pers-lname">Last Name<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-textfield tf-lname" type="text" data-test="business-lname" id="pfs-pers-lname" aria-describedby="pfs-pers-lname-error" name="last-name"
                v-model="appLName" :class="missingLName || invalidLName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateLName" required>
              <div id="pfs-pers-lname-error" class="ind-err-container">
                <div v-if="missingLName || invalidLName" class="form-error-msg" data-test="business-lname-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <!--Social Security -->
          <div class="ll-item">
            <div class="tf-label" id="personal-label-app-ssn"><label for="pfs-pers-ssn">Social Security Number or ITIN<sup class="fhb-red">*</sup></label>
            </div>
            <social-input class="ll-textfield tf-ssn" v-model="applicant_ssn" data-test="personal-ssn" id="pfs-pers-ssn" name="ssn" aria-describedby="pfs-ssn-note pfs-pers-ssn-error"
              :class="missingSSN || invalidSSN ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateSSN" required />
            <div id="pfs-ssn-note" class="tf-note">
              We use your SSN to verify your identity. Your personal information is transmitted securely through this online form.
            </div>
            <div id="pfs-pers-ssn-error" class="ind-err-container">
              <div v-if="missingSSN || invalidSSN" class="form-error-msg" data-test="personal-ssn-error">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please enter a valid SSN</div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />
          <!-- Phone Number -->
          <div class="ll-item">
            <div class="tf-label" id="business-label-app-phone"><label for="pfs-pers-tel">Phone Number<sup class="fhb-red">*</sup></label></div>
            <div class="ll-prefix-wrap">
              <phone-input class="ll-textfield tf-phone" v-model="appPhone" id="pfs-pers-tel" name="telephone" aria-describedby="pfs-phone-note pfs-pers-tel-error"
                :class="missingPhone || invalidPhone ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validatePhone"
                data-test="contact-app-phone" required />
            </div>
            <div id="pfs-phone-note" class="tf-note">
              Please provide a U.S. phone number.
            </div>
            <div id="pfs-pers-tel-error" class="ind-err-container">
              <div v-if="missingPhone || invalidPhone" class="form-error-msg" data-test="contact-app-phone-error">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="ll-row row2-container ll-item">
            <!-- Email -->
            <div>
              <div class="tf-label" id="business-label-app-email"><label for="pfs-pers-email">Email<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" data-test="tf-email" class="ll-textfield" id="pfs-pers-email" aria-describedby="pfs-pers-email-note pfs-pers-email-error" type="text" name="email" v-model="email"
                :class="missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateEmail" required>
              <div id="pfs-pers-email-note" class="tf-note">
                To contact you about your inquiry and to send you special offers/information. You may opt out of marketing communications at any time.
              </div>
              <div id="pfs-pers-email-error" class="ind-err-container">
                <div v-if="missingEmail || invalidEmail" class="form-error-msg" data-test="err-msg-email">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid email</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />
          <!-- row 4 : current address, apt-->
          <div class="ll-row row3-container">

            <div class="ll-item ll-address">
              <div class="tf-label" id="personal-fi-address"><label for="pfs-pers-stadd">Residence Street Address<sup class="fhb-red">*</sup></label></div>
              <input maxlength="60" class="ll-textfield tf-address" type="text" id="pfs-pers-stadd" aria-describedby="pfs-pers-addr-error" name="address" v-model="applicant_addr"
                :class="missingAddress || invalidAddress? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateAddress"
                data-test="contact-addr" required>
              <div id="pfs-pers-addr-error" class="ind-err-container">
                <div v-if="missingAddress || invalidAddress" class="form-error-msg" data-test="contact-addr-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter an address</div>
                </div>
              </div>
            </div>

            <div class="ll-item">
              <div class="tf-label" id="personal-fi-apt"><label for="pfs-pers-stunit">Apt, Unit, etc.</label></div>
              <input maxlength="5" :class="invalidApt? 'll-textfield-error' : 'll-textfield tf-apt'" type="text" id="pfs-pers-stunit" aria-describedby="pfs-pers-stunit-error" name="unit" v-model="applicant_apt"
                v-on:blur="validateApt"
                data-test="contact-addr2">
              <div id="pfs-pers-stunit-error" class="ind-err-container">
                <div v-if="invalidApt" class="form-error-msg" data-test="contact-addr-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid apt or unit.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />

          <!-- row 5 : city, state, zip-->
          <div class="ll-row row3-container">

            <div class="ll-item ll-city">
              <div class="tf-label" id="personal-fi-city"><label for="pfs-pers-stcity">City<sup class="fhb-red">*</sup></label></div>
              <input maxlength="28" class="ll-textfield tf-city" type="text" id="pfs-pers-stcity" aria-desscribedby="pfs-pers-stcity-error" name="city" v-model="applicant_city"
                :class="missingCity || invalidCity ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateCity"
                data-test="contact-city" required>
              <div id="pfs-pers-stcity-error" class="ind-err-container">
                <div v-if="missingCity || invalidCity" class="form-error-msg" data-test="contact-city-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a city</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-state">
              <div class="tf-label" id="personal-fi-state"><label for="pfs-pers-ststate">State or Territory<sup class="fhb-red">*</sup></label></div>
              <select class="ll-select select-state" name="us_state" id="pfs-pers-ststate" aria-describedby="pfs-pers-ststate-error" v-model="applicant_state"
                :class="missingState ? 'll-select-error' : 'll-select'" required @change="validateState($event)"
                data-test="contact-state">
                <option value="">Select a state</option>
                <option v-for="us_state in us_states" :value="us_state.value" v-bind:key="us_state.key">{{ us_state.key
                }}</option>
              </select>
              <div id="pfs-pers-ststate-error" class="ind-err-container">
                <div v-if="missingState" class="form-error-msg" data-test="contact-state-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select a state</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-zipcode">
              <div class="tf-label" id="personal-fi-zipcode"><label for="pfs-pers-stzip">ZIP Code<sup class="fhb-red">*</sup></label></div>
              <input maxlength="5" class="ll-textfield tf-zipcode" type="text" id="pfs-pers-stzip" aria-describedby="pfs-pers-stzip-error" name="zipcode" v-model="applicant_zipcode"
                :class="missingZipcode || invalidZipcode ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateZipcode" data-test="contact-zipcode" required>
              <div id="pfs-pers-stzip-error" class="ind-err-container">
                <div v-if="missingZipcode || invalidZipcode" class="form-error-msg" data-test="contact-zipcode-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                </div>
              </div>
            </div>
          </div>


          <div class="v-spacer-30" />
          <div class="v-spacer-30" />


          <div class="ll-row row2-container">
            <!-- Employer -->
            <div class="ll-item">
              <div class="tf-label" id="personal-fi-app-employer"><label for="pfs-pers-emp">Employer<sup class="fhb-red">*</sup></label></div>
              <input maxlength="50" class="ll-textfield" data-test="business-fname" type="text" id="pfs-pers-emp" aria-describedby="pfs-pers-emp-error" name="employer" v-model="appEmployer"
                :class="missingAppEmployer ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateAppEmployer" required>
              <div id="pfs-pers-emp-error" class="ind-err-container">
                <div v-if="missingAppEmployer" class="form-error-msg" data-test="business-fname-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter your employer</div>
                </div>
              </div>
            </div>
            <!-- Occupation -->
            <div class="ll-item">
              <div class="tf-label" id="personal-fi-app-occ"><label for="pfs-pers-occ">Occupation<sup>&nbsp;</sup><sup class="fhb-red">*</sup></label>
              </div>
              <input maxlength="50" class="ll-textfield" data-test="business-mi" type="text" id="pfs-pers-occ" aria-describedby="pfs-pers-occ-error" name="occupation"
                v-model="appOcc" :class="missingAppOcc ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateAppOcc" required>
              <div id="pfs-pers-occ-error" class="ind-err-container">
                <div v-if="missingAppOcc" class="form-error-msg" data-test="business-mi-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter your occupation</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img class="back-icon" srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png" alt="" />
              <span class="sr-only">Back</span>
            </button>
            <button class="continue-btn" id="ll-continue-btn" type="button" @click="handleContinue()"
              data-test="nav-continue">
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />

        </section>
      </form>
    </div>
    <Footer />
  </div>

</template>

<script>
import Api from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import Validator from '@/app/validation'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import Breadcrumb_personalFi from '@/views/business/personal_fi_statement/Breadcrumb_personalFi.vue'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'
import Constants from '@/app/business_constants'
import Utils from '@/app/utils'
import PhoneInput from '@/components/CustomInputs/PhoneInput.vue'
import SocialInput from '@/components/CustomInputs/SocialInput.vue'

export default {
  name: 'PersonalFi Personal Information',
  components: {
    Header,
    MainId,
    Footer,
    Breadcrumb_personalFi,
    PhoneInput,
    SocialInput
  },
  data() {
    return {
      systemErrorMsg: '',
      
      us_states: Constants.USStates,

      errCnt: 0,

      missingSSN: false,
      invalidSSN: false,

      missingAddress: false,
      invalidAddress: false,

      invalidApt: false,

      missingCity: false,
      invalidCity: false,

      missingState: false,

      missingZipcode: false,
      invalidZipcode: false,

      missingAppEmployer: false,

      missingAppOcc: false,

      missingFName: false,
      invalidFName: false,

      invalidMName: false,

      missingLName: false,
      invalidLName: false,

      missingEmail: false,
      invalidEmail: false,

      missingPhone: false,
      invalidPhone: false,

      owner_data: null,
    }
  },
  created() {
    this.setCurrentPage(1);
    this.setBusinessCreditID(this.$route.query.business_credit_id ? this.$route.query.business_credit_id : '')
    this.setBusinessCreditOwnerID(this.$route.query.business_credit_owner_id ? this.$route.query.business_credit_owner_id : '')
    if (this.business_credit_id != '') {
      this.getOwnerInfo()
    }
  },
  computed: {
    ...mapGetters("personalFiStatement",
      ['currentPage', 'type', 'application_id', 'business_credit_id', 'business_credit_owner_id']
    ),
    ...mapGetters("questionnaire", ['qid']),
    appFName: {
      get() { return this.$store.state.personalFiStatement.applicant.first_name },
      set(value) { this.$store.commit('personalFiStatement/setAppFirstName', value) }
    },
    appMI: {
      get() { return this.$store.state.personalFiStatement.applicant.middle_initial },
      set(value) { this.$store.commit('personalFiStatement/setAppMiddleInitial', value) }
    },
    appLName: {
      get() { return this.$store.state.personalFiStatement.applicant.last_name },
      set(value) { this.$store.commit('personalFiStatement/setAppLastName', value) }
    },
    applicant_ssn: {
      get() { return this.$store.state.personalFiStatement.applicant.ssn },
      set(value) { this.$store.commit('personalFiStatement/setAppSsn', value) }
    },
    email: {
      get() { return this.$store.state.personalFiStatement.applicant.email },
      set(value) { this.$store.commit('personalFiStatement/setAppEmail', value) }
    },
    appPhone: {
      get() { return this.$store.state.personalFiStatement.applicant.phone_number },
      set(value) { this.$store.commit('personalFiStatement/setAppPhoneNumber', value) }
    },
    applicant_addr: {
      get() { return this.$store.state.personalFiStatement.applicant.current_address },
      set(value) { this.$store.commit('personalFiStatement/setAppAddress', value) }
    },
    applicant_apt: {
      get() { return this.$store.state.personalFiStatement.applicant.current_apt_unit },
      set(value) { this.$store.commit('personalFiStatement/setAppApt', value) }
    },
    applicant_city: {
      get() { return this.$store.state.personalFiStatement.applicant.current_city },
      set(value) { this.$store.commit('personalFiStatement/setAppCity', value) }
    },
    applicant_state: {
      get() { return this.$store.state.personalFiStatement.applicant.current_state_or_territory },
      set(value) { this.$store.commit('personalFiStatement/setAppStateOrTerri', value) }
    },
    applicant_zipcode: {
      get() { return this.$store.state.personalFiStatement.applicant.current_zipcode },
      set(value) { this.$store.commit('personalFiStatement/setAppZip', value) }
    },
    appEmployer: {
      get() { return this.$store.state.personalFiStatement.applicant.employer },
      set(value) { this.$store.commit('personalFiStatement/setAppEmployer', value) }
    },
    appOcc: {
      get() { return this.$store.state.personalFiStatement.applicant.occupation },
      set(value) { this.$store.commit('personalFiStatement/setAppOccupation', value) }
    },
  },
  mounted() {
    this.setType('commercial-loan')
    jQuery("html, body").animate({ scrollTop: 0 });

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    document.getElementById("current_date").innerHTML = month + "/" + day + "/" + year;

  },
  methods: {
    ...mapActions("personalFiStatement",
      ['setCurrentPage', 'setApplicationID', 'setJWTToken', 'setType', 'setAppFirstName', 'setAppBankerIndicator', 'setAppPreferredBankerName', 'setAppMiddleInitial', 'setAppLastName', 'setAppEmail', 'setAppPhoneNumber', 'setAppCompanyPosition', 'setAppAgreeToTerms', 'setApplicationID', 'setValidatedNavPage', 'setBusinessCreditID', 'setBusinessCreditOwnerID']
    ),
    async getOwnerInfo() {
      const resp = await Api.getCreditAppOwner(this.business_credit_id, this.business_credit_owner_id, localStorage.getItem('token'))
      if (resp && resp.data && resp.data.response) {
        this.owner_data = resp.data.response.owner
        if (this.owner_data) {
          this.appFName = this.owner_data.first_name
          this.appMI = this.owner_data.middle_initial
          this.appLName = this.owner_data.last_name
          this.appPhone = this.owner_data.phone.replaceAll(" ", "",).replaceAll("-", "",).replaceAll("+1", "",).replaceAll("(", "",).replaceAll(")", "",)
          this.email = this.owner_data.email
          this.applicant_addr = this.owner_data.street_address
          this.applicant_apt = this.owner_data.street_address_2
          this.applicant_city = this.owner_data.street_address_city
          this.applicant_state = this.owner_data.street_address_state
          this.applicant_zipcode = this.owner_data.street_address_zipcode
        }
      }
    },
    async postPagePersonalFi(payload) {
      this.systemErrorMsg = '';
      this.systemErrorArray = [];

      const resp = await Api.postPagePersonalFi(payload);
      console.log('--- response ---');
      console.log(resp);

      if (resp && resp.data) {

        if (resp.data.status == "success") {
          this.setJWTToken(resp.data.access_token);

          if (resp.data.response) {
            this.setApplicationID(resp.data.response.application_id);
          }
          // update breadcrumb state
          this.setValidatedNavPage({ name: 'personal', isValid: 1 });

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400));

          this.$router.push({ name: 'Assets And Liabilities' })

          Utils.removeProcessingMask('#ll-continue-btn')
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }

          if (resp.data.response.application_errors && (resp.data.response.application_errors.length > 0)) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message);
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask('#ll-continue-btn')
        }
      }
    },
    handleBack() {
      this.$router.push({ name: 'Getting Started' })
    },
    handleContinue() {

      Utils.addProcessingMask('#ll-continue-btn')


      this.validatePage();

      this.$nextTick(() => {
        this.errCnt = jQuery('.form-error-txt').length;

        if (!this.errCnt) {
          var payload = {
            "questionnaire_id": this.qid,
            "page": this.currentPage,
            "first_name": this.appFName,
            "MiddleInitial": this.appMI,
            "last_name": this.appLName,
            "email": this.email,
            "phone_number": this.appPhone ? Utils.formatPhoneNumber(this.appPhone) : "",
            "employer": this.appEmployer,
            "occupation": this.appOcc,
            "current_address": this.applicant_addr,
            "current_apt_unit": this.applicant_apt,
            "current_city": this.applicant_city,
            "current_state_or_territory": this.applicant_state,
            "current_zipcode": this.applicant_zipcode,
          }

          if (this.business_credit_id != '') {
            payload.business_credit_id = this.business_credit_id
            payload.business_credit_owner_id = this.business_credit_owner_id
          }

          this.postPagePersonalFi(payload);
        } else {
          this.setValidatedNavPage({ name: 'personal', isValid: 0 });

          if (this.errCnt > 1) {
            jQuery("html, body").animate({ scrollTop: jQuery("#commercial-page-errors").offset().top - 20 });
          } else {
            let offset = 0;

            if (this.missingFName || this.invalidFName) offset = jQuery("#business-label-app-fname").offset().top
            if (this.invalidMName) offset = jQuery("#business-label-app-mi").offset().top
            if (this.missingLName || this.invalidLName) offset = jQuery("#business-label-app-lname").offset().top
            if (this.missingEmail || this.invalidEmail) offset = jQuery("#business-label-app-email").offset().top
            if (this.missingPhone || this.invalidPhone) offset = jQuery("#business-label-app-phone").offset().top
            if (this.missingPosition) offset = jQuery("#business-label-app-position").offset().top
            if (this.missingBankerName || this.invalidBankerName) offset = jQuery("#business-label-app-banker-name").offset().top
            if (this.missingAgree) offset = jQuery("#business-label-app-agree").offset().top
            if (this.missingAddress || this.invalidAddress) offset = jQuery("#personal-fi-address").offset().top
            if (this.invalidApt) offset = jQuery("#personal-fi-apt").offset().top
            if (this.missingCity || this.invalidCity) offset = jQuery("#personal-fi-city").offset().top
            if (this.missingState) offset = jQuery("#personal-fi-state").offset().top
            if (this.missingZipcode || this.invalidZipcode) offset = jQuery("#personal-fi-zipcode").offset().top
            if (this.missingAppEmployer) offset = jQuery("#personal-fi-app-employer").offset().top
            if (this.missingAppOcc) offset = jQuery("#personal-fi-app-occ").offset().top

            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask('#ll-continue-btn')
        }

      })

    },
    validatePage() {

      this.validateFName();
      this.validateMI();
      this.validateLName();
      this.validateEmail();
      this.validatePhone();
      this.validateSSN();
      this.validateAddress();
      this.validateCity();
      this.validateState();
      this.validateZipcode();
      this.validateAppEmployer();
      this.validateAppOcc();

    },
    validateFName() {
      this.appFName = this.appFName.trim()
      this.missingFName = this.appFName ? false : true;
      if (!this.missingFName) {
        this.invalidFName = Validator.isValidName(this.appFName) ? false : true;
      }
    },
    validateMI() {
      this.appMI = this.appMI.trim()
      if (this.appMI) {
        this.invalidMName = Validator.isValidName(this.appMI) ? false : true;
      }
    },
    validateLName() {
      this.appLName = this.appLName.trim()
      this.missingLName = this.appLName ? false : true;
      if (!this.missingLName) {
        this.invalidLName = Validator.isValidName(this.appLName) ? false : true;
      }
    },
    validateEmail() {
      this.email = this.email.trim()
      this.missingEmail = this.email ? false : true;
      if (!this.missingEmail) {
        this.invalidEmail = Validator.isValidEmail(this.email) ? false : true;
      }
    },
    validatePhone() {
      this.missingPhone = this.appPhone ? false : true;
      if (!this.missingPhone) {
        this.invalidPhone = Validator.isValidPhoneNumber(this.appPhone) ? false : true;
      }
    },
    validateAddress() {
      this.applicant_addr = this.applicant_addr.trim()
      this.missingAddress = (!this.applicant_addr || this.applicant_addr == "")
      if (!this.missingAddress) {
        const regex = /^[a-zA-Z0-9 -]+$/
        this.invalidAddress = !this.applicant_addr.match(regex)
      }
    },
    validateApt() {
      this.applicant_apt = this.applicant_apt.trim()
      const regex = /^[a-zA-Z0-9 -]*$/
      this.invalidApt = (this.applicant_apt && !this.applicant_apt.match(regex))
    },  
    validateCity() {
      this.applicant_city = this.applicant_city.trim()
      this.missingCity = !this.applicant_city

      const regex = /^[a-zA-Z0-9 -]*$/
      if (!this.missingCity) {
        this.invalidCity = !this.applicant_city.match(regex)
      }
    },
    validateState() {
      this.missingState = this.applicant_state ? false : true;
    },

    validateZipcode() {
      this.applicant_zipcode = this.applicant_zipcode.trim()
      this.missingZipcode = this.applicant_zipcode ? false : true;
      if (!this.missingZipcode) {
        this.invalidZipcode = Validator.isValidZipcode(this.applicant_zipcode) ? false : true;
      }
    },
    validateAppEmployer() {
      this.appEmployer = this.appEmployer.trim()
      this.missingAppEmployer = this.appEmployer ? false : true;
    },
    validateAppOcc() {
      this.appOcc = this.appOcc.trim()
      this.missingAppOcc = this.appOcc ? false : true;
    },

    validateSSN() {
      this.missingSSN = this.applicant_ssn ? false : true;
      if (!this.missingSSN) {
        this.invalidSSN = Validator.isValidSSN(this.applicant_ssn) ? false : true;
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },
  }
}
</script>


<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 2.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {}
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  >div+div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.ll-city {
  @include media-breakpoint-up(xl) {
    .tf-city {
      min-width: 380px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex: 1;
    max-width: unset;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}
</style>